import React, { useState } from 'react';
import './popup.css';

const Popup = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // For example, send data to a server
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      <p></p>
        <h2>Application</h2>

        <form action="https://formsubmit.co/3823651ebc1969d78ffb0fad02faced4" method='POST' enctype="multipart/form-data">

<table border="0" cellpadding="5" cellspacing="0">
<tr> <td style={{width: "50%"}}>
<label for="First_Name"><b>First name *</b></label><br />
<input name="First_Name" type="text" maxlength="50" style={{width: "100%", maxWidth:"250px"}} />
</td> <td style={{width: "50%"}}>
<label for="Last_Name"><b>Last name *</b></label><br />
<input name="Last_Name" type="text" maxlength="50" style={{width: "100%", maxWidth:"250px"}}/>
</td> </tr> <tr> <td colspan="2">
<label for="Email_Address"><b>Email *</b></label><br />
<input name="Email_Address" type="text" maxlength="100" style={{width: "100%", maxWidth:"550px"}} />
</td> </tr> <tr> <td colspan="2">

<label for="Phone"><b>Phone *</b></label><br />
<input name="Phone" type="text" maxlength="50" style={{width: "100%", maxWidth:"390px"}}/>
</td> <td>
</td> </tr> <tr> <td colspan="2">
<label for="Relocate"><b>Are you willing to relocate?</b></label><br />
<input name="Relocate" type="radio" value="Yes" checked="checked" /> Yes      
<input name="Relocate" type="radio" value="No" /> No      
<input name="Relocate" type="radio" value="NotSure" /> Not sure
</td> </tr> <tr> <td colspan="2">
<label for="Organization"><b>Last company you worked for</b></label><br />
<input name="Organization" type="text" maxlength="100" style={{width: "100%", maxWidth:"550px"}}/>
</td> </tr> <tr> <td colspan="2">
<label for="Reference"><b>Reference / Motivation / Questions</b></label><br />
<textarea name="Reference" rows="7" cols="40" style={{width: "100%", maxWidth:"550px"}}></textarea>
</td> </tr> <tr> <td colspan="2" style={{textAlign: "center"}}>
<input name="skip_submit" type="submit" value="Send Application" />
</td> </tr>
</table>
</form>
      </div>
    </div>
  );
};

export default Popup;
