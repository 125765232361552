import React, { useState, useEffect } from 'react'
import './clientSection.css' 
import { clients } from '../../data';

const ClientSection = ({logo, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % 5);
  };

  useEffect(() => {
    const timer = setInterval(handleNext, interval);
    return () => clearInterval(timer);
  }, [currentIndex]);

  return (

    <div className="clientSection-container">
    <div className='section-divider'>
  <span>───●◎●───</span>
</div>
    <h2>Our Partners</h2>
    <div className="logo-slider-container">
      <div className="logo-slider-track" style={{ transform: `translateX(-${currentIndex * 80}%)` }}>
        {
            clients.map(({logo},index)=> {
                return (
          <div key={index} className="logo-slide">
            <img src={logo} alt={`Logo ${index}`} />
          </div>
            )
})}
      </div>
    </div>
    </div>
  );
}

export default ClientSection;
