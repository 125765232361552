import React from 'react'
import RobotImage from '../../images/robo.png'
import './section2.css'

const ServiceSection = () => {
  return (
    <section>
         <div class="row">
        <div class="section_container">
                       <figure>
                <img src={RobotImage} alt=" Image by kjpargeter on freepik"/>
              </figure>
              <div class="section_header">
                <h2>Your Business <br/><span>Our Responsibility </span></h2>
                <p>We are dedicated in perfection of Services to our valued customers through our expertise in Employee Outsourcing, recruitment and Human Resource Service.</p>

              <div class="section-left">
                <a href="/services" class="btn">More</a>
              </div>
            </div> 

          </div> 
        </div> 
    </section>


  )
}

export default ServiceSection