import React from 'react'
import './mainheader.css'
import {Link} from 'react-router-dom'
import Main from '../../images/header/mainheader.png'

const MainHeader = () => {
  return (
    <div className="main-header">
        <div className="header-left">
        <h4> 7 YEARS OF EXPERIENCE </h4>
          <h1>We are a Trusted<span> Recruitment</span> Agency</h1>
          <div className='header-icon'>
            <span>───●◎●───</span>
          </div>
          <p>
          Krayrpol recruitment agency is one step solution for your all manpower needs. We are dedicated in perfection of Services to our valued customers through our expertise in Employee Outsourcing, recruitment and Human Resource Service.
          </p>
          {/* <Link to="/offers" className='btn lg'>Our offers </Link> */}
        </div>
        <div className="header-right">
        <div class="border"></div>
        <div className="main-header-img">
            {/* <img src ={Main} alt='Main Header image'/> */}
            </div>
        </div>


    </div>
  )
}

export default MainHeader