
const Box = ({className,children}) => {
    return (
      <article className={`box ${className}`}>
  
        {children}
        </article>
    )
  }
  
  export default Box