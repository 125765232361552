import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import CandidateImage from '../../images/pages/employee.jpg'
import CEOImage from '../../images/pages/CEO.png'

import Image1 from '../../images/Icon.png'
import Video1 from '../../images/video.mp4'


import './services.css'



const Services =  () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
  

    <section >
        <div class="row ">                
                <div class="content-column ">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">Our Services</span>
                            <h2>We Provide The Best  <span sr={{fontColor:"#9b4314"}}> Recruitment Service!</span> </h2>
                        </div>
                        <div className='sec-divider'>
                            <span>───●◎●───</span>
                         </div>

                        <div class="text"> We pride ourselves on our commitment to excellent customer service, competitive rates, and innovative approaches to staffing and HR management.</div>
                        <div class="btn-box">
                            <a href="/contact" class="btn-style-one" style={{marginTop:'1rem'}}>Contact Us</a>
                        </div>
                    </div>
                </div>

                <div class="jkj">
                    <div class="hero-img">

                        {/* <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img title="About us" src={CandidateImage} alt=""/></a></figure> */}
                     
                    </div>
                </div>
              
            </div>
            <div class="we-offer-area text-center bg-gray">
      <div class="site-heading text-center">
        <div className='section-divider'>
          <span>───●◎●───</span>
        </div>
          <h2>What we <span>Offer</span></h2>
          <h4 style={{fontColor:'#0c4b53'}}>We provide wide range of Recruitment and Human Resources Services.</h4>
          </div>
          <div class="items ">
                <div class="item">
                    <i class="fas fa-pen-fancy"><img src={Image1}/></i>
                    <h4>Outsourcing</h4>
                    

                    <p>
                    we provide comprehensive employee outsourcing services. Our experienced team handles the recruitment and management of your staff, allowing you to focus on your core business operations and improve your bottom line. Let us help you streamline your workforce and maximize your productivity with our tailored outsourcing solutions.                    </p>
            </div>
            

                <div class="item">
                    <i class="fas fa-dharmachakra"><img src={Image1}/></i>
                    <h4>Recruitment</h4>

                    <p>
                    As a temporary recruitment agency, we provide businesses with highly skilled and experienced temporary workers who are available to meet short-term staffing needs. Our services include recruitment, screening, and placement of workers, as well as handling payroll and other administrative tasks. Let us provide you with the temporary workers you need to keep your business running smoothly.                    </p>
            </div>

                <div class="item">
                    <i class="fas fa-tasks"><img src={Image1}/></i>
                    <h4>HR Services</h4>
                    <p>
                    Our HR service and consulting provide businesses with expert guidance and support on all aspects of HR management. Our services include policy development, compliance management, performance management, employee training and development, and more. Let us help you build a strong HR foundation for your business and maximize your human capital potential. </p>
            </div>
          </div>
          </div>
{/* another section here */}
<div class="row">
        <div class="section_container">
                       <figure>
                <img src={CEOImage} alt=" Image by kjpargeter on freepik"/>
              </figure>
              <div class="section_header">
                <h2>Message From<br/><span>CEO </span></h2>
                  <p>We're so excited to have you on board. We hope you'll find this company to be a fun and rewarding place to work. Our culture is one of collaboration, innovation, and—above all else—caring for our clients' needs.</p>
              {/* <div class="section-left">
                <a href="/contact" class="btn">Contact Us</a>
              </div> */}
            </div> 
          </div> 
        </div> 
    </section>
 )
}

export default Services