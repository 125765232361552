import React from 'react'
import { BrowserRouter,Routes, Route } from 'react-router-dom'
import Navbar from './components/headers/Navbar'
import Home from './pages/home/Home'
import Footer from './components/footer/Footer'
import About from './pages/about/About'
import Services from './pages/services/Services'
import Jobs from './pages/jobs/Jobs'
import Contact from './pages/contact/Contact'

const NotFound = () => {
  return <div>Page not found</div>;
};


const App = () => {
  return (
    <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route index element={<Home/>}/>
      <Route path='about' element={<About/>}/>
      <Route path='services' element={<Services/>}/>
      <Route path='jobs' element={<Jobs/>}/>
      <Route path='contact' element={<Contact/>}/>
      <Route element={<NotFound/>}></Route>



    </Routes>
    <Footer/>
    </BrowserRouter>
  )
}

export default App