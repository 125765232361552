import {SiOpenaigym} from 'react-icons/si'
 import {FaHandsHelping} from 'react-icons/fa'
 import {BsBriefcaseFill} from 'react-icons/bs'
 import {GiTeamIdea} from 'react-icons/gi'

export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },


    {
        name: "Services",
        path: '/services'
    },

    {
        name: "Jobs",
        path: '/jobs'
    },

    {
        name: "Contact",
        path: '/contact'
    }
]

export const jobs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Excellence",
        desc: "We strive for excellence in everything we do. We are committed to delivering high-quality services, exceeding client expectations, and continuously improving our processes and skills."
    },
    {
        id: 2,
        icon: <FaHandsHelping/>,
        title: "Integrity",
        desc: "We uphold the highest standards of integrity and ethics in all our interactions. We believe in transparency, honesty, and maintaining confidentiality in handling client information."
    },
    {
        id: 3,
        icon: <BsBriefcaseFill/>,
        title: "Collaboration",
        desc: "We foster a collaborative and inclusive environment, both internally and with our clients. We believe in the power of teamwork, knowledge sharing, and leveraging diverse perspectives to achieve mutual success."
    },
    {
        id: 4,
        icon: <GiTeamIdea/>,
        title: "Client-Centric Approach",
        desc: "Our clients are at the center of everything we do. We listen to their needs, understand their goals, and tailor our solutions to meet their specific requirements. We are dedicated to building strong, long-term relationships based on trust and mutual success."
    }
]

export const clients = [
    {
        logo: require('../src/images/clients/akpol.png')
    },
    {
        logo: require('../src/images/clients/elite.png')
    }, 
    {
        logo: require('../src/images/clients/dewnod.png')
    }, 
    {
        logo: require('../src/images/clients/mpack.png')
    }, 
    // {
    //     logo: require('../src/images/clients/client_3.png')
    // }, 
    {
        logo: require('../src/images/clients/download.png')
    }
]

export const testimonials = [
    {
        id: 1,
        name: "Artem ",
        quote: "We have been working with Krayrpol for three years and I am happy to say that they are our trusted IT service provider. They provide us with high-quality services, as well as practical solutions to any issues we have. The team is professional, reliable and honest.",
        job: "Gorgol kancelaria ",
        avatar: require("../src/images/testimonials/man1.png")
    },
    {
        id: 2,
        name: "Daniela Vinyo",
        quote: "I would highly recommend Krayrpol sp. z o,o for any of your recruitment, temporary hire, or employee outsourcing needs.",
        job: "PackingPro Sp z o o",
        avatar: require("../src/images/testimonials/lady1.png")
    },
    {
        id: 3,
        name: "Piotr Dudzinski",
        quote: "I was in need of a Recuitment agency and Krayrpol came to my rescue. I had a very short deadline, but they were able to find me the best workers with minimal effort on my part. They were professional and courteous at all times, and their high quality services were worth every penny.",
        job: "Mpack Poland sp. z o.o",
        avatar: require("../src/images/testimonials/man2.png")

    },
]

export const service = [
    {
        icon: require("../src/images/Icon.png"),
        title:"Outsourcing",
        desc: " we provide comprehensive employee outsourcing services. Our experienced team handles the recruitment and management of your staff, allowing you to focus on your core business operations and improve your bottom line. Let us help you streamline your workforce and maximize your productivity with our tailored outsourcing solutions",


    },
    {
        icon: require("../src/images/Icon.png"),
        title:"Recruitment",
        desc: " As a temporary recruitment agency, we provide businesses with highly skilled and experienced temporary workers who are available to meet short-term staffing needs. Our services include recruitment, screening, and placement of workers, as well as handling payroll and other administrative tasks. Let us provide you with the temporary workers you need to keep your business running smoothly.",
       

    },
    {
        icon: require("../src/images/Icon.png"),
        title:"HR Services",
        desc: "Our HR service and consulting provide businesses with expert guidance and support on all aspects of HR management. Our services include policy development, compliance management, performance management, employee training and development, and more.Let us help you build a strong HR foundation for your business and maximize your human capital potential. ",
       

    },
]  
export const joblist = [
    { 
        id: 1,
        image: require('../src/images/jobs/driver.jpg'),
        position: 'Driver C+E', 
        location: 'Warsaw',
        desc:'Operate commercial trucks to safely transport goods over long distances, adhering to traffic laws and ensuring timely deliveries. Responsible for vehicle maintenance and maintaining accurate transportation records.',
    },
    { 
        id: 2, 
        image: require('../src/images/jobs/butcher.jpg'),
        position: 'Butcher', 
        location: 'Mokobody-Mazowieckie',
        desc:'Responsible for expertly preparing and cutting a variety of meats to the highest quality standards while ensuring food safety and maintaining a clean work environment. Skilled in butchery techniques and knowledgeable about different cuts of meat.',

    },
    { 
        id: 3, 
        image: require('../src/images/jobs/warehouseman.jpg'),
        position: 'Warehouse Worker', 
        location: 'Katowice',
        desc:'Responsible for efficiently managing inventory, picking and packing orders, and maintaining a tidy and organized warehouse environment. Collaborates with the team to ensure timely order fulfillment and accurate stock management.',
 
    },

    {
    id: 4, 
    image: require('../src/images/jobs/welder.jpg'),
    position: 'Welder', 
    location: 'Szczeczin',
    desc:'Perform precise and skilled welding operations on various metal components in accordance with industry standards. Ensure the structural integrity and quality of welded products through meticulous attention to detail and adherence to safety protocols.',

},
{
    id: 5, 
    image: require('../src/images/jobs/packer.jpg'),
    position: 'Packer', 
    location: 'Otwock',
    desc:'Responsible and efficient packer needed to accurately assemble and package products according to company standards, ensuring timely and quality delivery.'

},
{
    id: 6, 
    image: require('../src/images/jobs/constructionworker.jpg'),
    position: 'Construction Specialist', 
    location: 'Łomanki',
    desc:'Responsible construction worker experienced in various tasks such as site preparation, materials handling, and equipment operation. Collaborates with the team to ensure efficient and safe project execution.'

},
{
    id: 7, 
    image: require('../src/images/jobs/painter.jpg'),
    position: 'Spray Painter', 
    location: 'Częstochowa',
    desc:'Responsible spray painter experienced in applying finishes to various surfaces, ensuring high-quality and precise coatings while adhering to safety guidelines. Proficient in paint mixing, equipment operation, and surface preparation for optimal finishing results.'

},
{
    id: 8, 
    image: require('../src/images/jobs/fitter.jpg'),
    position: 'Fitter', 
    location: 'Szczeczin',
    desc:'The Fitter is responsible for assembling, installing, and maintaining mechanical systems and components according to blueprints and technical specifications, ensuring precision and functionality in various industrial settings'

},
{
    id: 9, 
    image: require('../src/images/jobs/machineOperator.jpg'),
    position: 'Machine Operator', 
    location: 'Krakow',
    desc:'Operate and monitor machinery in a safe and efficient manner to ensure production goals are met while maintaining quality standards. Perform routine inspections and adjustments to optimize machine performance.'

},
{
    id: 10, 
    image: require('../src/images/jobs/carpenter.jpg'),
    position: 'Carpenter', 
    location: 'Złocieniec',
    desc:'Craft intricate woodwork with precision and expertise as a skilled Carpenter in pallate production. Translate blueprints into finely-crafted structures while ensuring the highest standards of craftsmanship and attention to detail.'

},
{
    id: 11, 
    image: require('../src/images/jobs/farmworker.jpg'),
    position: 'Farm worker', 
    location: 'Poznan',
    desc:'Seeking a farm worker to assist in the picking of fruits and vegetables. Responsibilities include harvesting crops with care and efficiency, ensuring product quality, and maintaining a tidy work environment.'

},
{
    id: 12, 
    image: require('../src/images/jobs/productionhelper.jpg'),
    position: 'Production Helper', 
    location: 'Łódz',
    desc:'As a Production Helper, you will play a vital role in our production line operations. Your responsibilities will include assisting with tasks such as material handling, equipment setup, and ensuring a smooth workflow to contribute to efficient and high-quality production processes.'

},

    
    
    

];