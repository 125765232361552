import React, {useEffect} from 'react'
import MainHeader from '../../components/headers/MainHeader'
import Section1 from '../../components/sections/Section1'
import Card from '../../components/card/Card'
import './home.css'
import ServiceSection from '../../components/sections/ServiceSection'
import Clientsection from '../../components/sections/Clientsection'
import Testimonials from '../../components/sections/Testimonials'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <MainHeader/>
    <Card/>

    <Section1/>
    <ServiceSection/>
    <Clientsection/>
    <Testimonials/>
    </>
  )
}

export default Home