import SectionImage from '../../images/woman.png'
import './section1.css'
import {FcServices} from 'react-icons/fc'

const Section1 = () => {
    return (
      <section>
        <div className="section-container">
        <div className='section-divider'>
      <span>───●◎●───</span>
    </div>
        <h2>What we do?</h2>
      <p>Comprehensive talent solutions, from sourcing to technology-driven optimizations, fostering growth, diversity, and efficiency</p>
        </div>
      <div className="columns">
         <div className="section-left">
                <ul class="list">
                  <li>
                      <span class="iconbox"><i class="icon-mobile"></i></span>
                        <h6><FcServices/> Talent Sourcing</h6>
                        <p>We find the right candidates for your company, ensuring a perfect fit for your team's needs.</p>
                  </li>
                  <li>
                      {/* <span class="iconbox"><i class="icon-browser"></i></span> */}
                        <h6><FcServices/>Screening and Assessment</h6>
                        <p>Rigorous evaluations to match skills and culture, ensuring high-quality hires.</p>
                  </li>
                  <li>
                      {/* <span class="iconbox"><i class="icon-strategy"></i></span> */}
                        <h6><FcServices/>  Job Posting and Marketing</h6>
                        <p>We create enticing job postings to attract top talent, expanding your candidate pool.</p>
                  </li>
                 
                  <li>
                      {/* <span class="iconbox"><i class="icon-hotairballoon "></i></span> */}
                        <h6><FcServices/> HR Consulting</h6>
                        <p>Expert advice on HR policies, compliance, and strategies to enhance your workforce.</p>
                  </li>
                  <li>
                      {/* <span class="iconbox"><i class="icon-lightbulb"></i></span> */}
                        <h6><FcServices/>  Interview Coordination</h6>
                        <p>Efficiently manage interviews, saving you time and resources.</p>
                  </li>
                </ul> 


        </div>
        <div className="section-img">
          <img src={SectionImage} alt='Section Image'/>
        </div>
        <div className="section-left">
        <ul class="features-list">
                  <li>
                      {/* <span class="iconbox"><i class="icon-mobile"></i></span> */}
                        <h6><FcServices/>  Candidate Experience </h6>
                        <p>We prioritize a positive experience for candidates, enhancing your employer brand.</p>
                  </li>
                  <li>
                      {/* <span class="iconbox"><i class="icon-browser"></i></span> */}
                        <h6><FcServices/>  Temporary Staffing</h6>
                        <p>roviding flexible staffing solutions to meet short-term business demands.</p>
                  </li>
                  <li>
                      {/* <span class="iconbox"><i class="icon-strategy"></i></span> */}
                        <h6><FcServices/>  Training and Development</h6>
                        <p>Helping your team grow through targeted training and skill-building programs.</p>
                  </li>
                 
                  <li>
                      {/* <span class="iconbox"><i class="icon-hotairballoon "></i></span> */}
                        <h6><FcServices/>  Diversity and Inclusion </h6>
                        <p>We promote diverse hiring, fostering an inclusive workplace culture.</p>
                  </li>
                  <li>
                      {/* <span class="iconbox"><i class="icon-lightbulb"></i></span> */}
                        <h6><FcServices/>  HR Tech Solutions</h6>
                        <p>Implementing innovative HR technology to streamline processes and boost efficiency.</p>
                  </li>
                </ul> 

        </div>
        </div>
   
      </section>
  )
    
}
  
  export default Section1