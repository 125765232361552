import React,{useEffect} from 'react'
import AboutImage from '../../images/pages/about-1.png'

import './about.css'


const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <section class="about-section">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title">
                            <span class="title">About Krayrpol</span>
                            <h2>We are Recruitment Experts <span sr={{fontColor:"#9b4314"}}> working since 2017</span> </h2>
                        </div>
                        <div className='sec-divider'>
                            <span>───●◎●───</span>
                         </div>

                        <div class="text">At Krayrpol employment and outsourcing agency, we are dedicated to providing businesses with top-quality staffing and HR solutions. With our extensive experience and expertise in the industry, we offer tailored outsourcing solutions to help businesses optimize their workforce, streamline operations, and achieve their goals. We pride ourselves on our commitment to excellent customer service, competitive rates, and innovative approaches to staffing and HR management. Let us help you achieve success by empowering your human capital with our exceptional services.</div>
                      <div class="text">
                      We're so excited to have you on board. We hope you'll find this company to be a fun and rewarding place to work. Our culture is one of collaboration, innovation, and—above all else—caring for our clients' needs.
                      </div>
                        <div class="btn-box">
                            <a href="/contact" class="btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">

                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img title="About us" src={AboutImage} alt=""/></a></figure>
                     
                    </div>
                </div>
              
            </div>
           <div class="sec-title-goal">
                            <span class="title">Our Future Goal</span>
                            <h2> "We want to lead in Human Resource Industry with innovation & Technology."</h2>
                        </div>
          <div class="text">
          Our story starts with the dream! A dream of enthusiasm, perfection and Quality in Business. We are a start-up with a mission to innovate staffing Solution for the business. We believe that Employee recruitment, Outsourcing and HR consulting sectors can be nourished by the professionalism and quality services we offer. We have a passionate team of individuals who are keen to make our dream come true. Our team members are highly motivated and dedicated towards achieving our goals. From day one, we've been committed to providing top-of-the-line Employee recruitment, Outsourcing and with an eye toward global HR industry—and we're proud of how far we've come.              </div>
               {/* <div class="text">
                We take a small toolkit here and ride it well so that it is fit for your use. One who performs well and looks even better.
              </div>
               <div class="text">                
Here we are trying to give you all kinds of technical content, whether it is related to designing or functionality. We are creating content on a lot of languages and will continue to make it free of cost even if you use it without any problem. Which is a very important thing.
              </div> */}
               <div class="text">
               we believe in a professional and personalized approach to staffing and HR management. We work closely with each of our clients to understand their unique needs and provide tailored solutions that align with their business objectives. Our team of experienced professionals is dedicated to delivering exceptional customer service, and we pride ourselves on our attention to detail and commitment to quality. With our innovative and flexible approach, we help businesses optimize their workforce, improve productivity, and achieve their goals. Trust us to be your partner in success.              </div>
               <div class="text">
                
We thank you for trusting us. Together we create the future!
              </div>
        </div>
    </section>
  )
}

export default About