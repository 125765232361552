import React from 'react'

const Card = () => {
  return (
    <div class="quote-container">
        <p class="quote-text">
            "The best way to predict the future is to create it."</p>
            <p class="quote-author">- Abraham Lincoln</p>
            </div>
  )
}

export default Card