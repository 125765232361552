import React, {useState} from 'react'
import {Link, NavLink} from 'react-router-dom';
import './navbar.css'
import Logo from '../../images/logo/logo.png'
import {links} from '../../data'
import {MdOutlineClose} from 'react-icons/md'
import {VscThreeBars} from 'react-icons/vsc'


const Navbar = () => {
  const [isNavShowing, setIsNavShowing]=useState(false);



  return (
<div class="nav">
  <div class="nav-header">
    <Link to=" " className='nav-img' onClick={()=> setIsNavShowing(false)} >
            <img className='nav-img' src={Logo} alt="Nav Logo" />
         </Link>
         <div class="nav-title">

         <ul className={`nav-links ${isNavShowing ? 'show__nav':'hide__nav'}`}>
            {
                links.map(({name, path},index)=> {
                    return (
                        <li key ={index}>
                            <NavLink to={path} className={({isActive}) => isActive ? 'active-nav' : '' } onClick={()=>setIsNavShowing(prev =>!prev)}> {name}</NavLink>
                        </li>
                    )
                })
            }
            </ul>
            <button className="nav-toggle-btn" onClick={()=> setIsNavShowing (prev =>!prev)}>
            {
                isNavShowing ? <MdOutlineClose/> : <VscThreeBars/>

            }
        </button>

  </div>
</div>
</div>

  )
}

export default Navbar