import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import Logo from '../../images/logo/logo.png'
import {FaLinkedin} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'

import './footer.css'
import {links} from '../../data'



const Footer = () => {
  return (
<footer class="footer-distributed">

<div class="footer-left">

    <h3>Krayr<span>Pol</span></h3>

    <p class="footer-links">
        {
            links.map(({name, path},index)=> {
                return (
                    <li key ={index}>
                        <NavLink to={path}> {name}</NavLink>
                    </li>
                )
            })
        }
    </p>

    <p class="footer-company-name">All Right Reserved © 2020</p>
</div>

<div class="footer-center">

    <div>
        <i class="fa fa-map-marker"></i>
        <p><span>Al. Solidarności 68/121</span> 00-240 Warszawa</p>
    </div>

    <div>
        <i class="fa fa-phone"></i>
        <p>+48 579 218 740</p>
    </div>

    <div>
        <i class="fa fa-envelope"></i>
        <p><a href="mailto:support@company.com">info@krayrpol.pl</a></p>
    </div>

</div>

<div class="footer-right">

    <p class="footer-company-about">
        <span>About the company</span>
        Krayrpol is one step solution for your all manpower needs. </p>
    <div class="footer-icons">
        <a href="https://www.linkedin.com/company/krayrpol/" target="_blank" rel="noreferrer noopener"><FaLinkedin/> </a>
        <a href=" https://facebook.com/krayrpol/" target="_blank" rel="noreferrer noopener"><FaFacebookF/> </a>
        <a href=" https://twitter.com/krayrpol/" target="_blank" rel="noreferrer noopener"><AiOutlineTwitter/> </a>
        <a href=" https://instagram.com/krayrpol/" target="_blank" rel="noreferrer noopener"><AiFillInstagram/> </a>

    </div>

</div>

</footer>
  )
}

export default Footer