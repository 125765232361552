import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceImage from '../../images/pages/header_bg_4.png';
import './job.css';
import { joblist } from '../../data';
import Popup from '../contact/Popup';

const Jobs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [jobs, setJobs] = useState([]);
  const history = useNavigate();
  const [positionFilter, setPositionFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [showResults, setShowResults] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    // Simulate fetching jobs from an API (you'd replace this with an actual API call)
    // setJobs(dummyJobs);
    setJobs(joblist);

  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setShowResults(true);
  };

  const handleGoBack = () => {
    setShowResults(false);
    setPositionFilter('');
    setLocationFilter('');
  };

  const filteredJobs = jobs.filter(
    (job) =>
      job.position.toLowerCase().includes(positionFilter.toLowerCase()) &&
      job.location.toLowerCase().includes(locationFilter.toLowerCase())
  );

  return (
    <div>
      {/* Common content */}
      <img className="bg-img" src={ServiceImage} />
      <div className="container">
     
        <h2>Find the Job you are looking for!</h2>
        <p >
          We have a lot of interesting job offers waiting for you.
        </p>
        {!showResults ? (
          <form className="form-box" onSubmit={handleSearch}>
            <div className="input-container">
              <input
                className="input"
                name="Name"
                placeholder="Position"
                value={positionFilter}
                onChange={(e) => setPositionFilter(e.target.value)}
              />
              <input
                className="input"
                name="Location"
                placeholder="Location"
                value={locationFilter}
                onChange={(e) => setLocationFilter(e.target.value)}
              />
            </div>
            <button type="submit" className="button">
              Search
            </button>
          </form>
        ) : (
          <div className="job-list">
            {filteredJobs.length === 0 ? (
              <div className='nojob '>
              <p>No jobs found. <br/>      
              <button onClick={handleGoBack} className="button">
              Go Back
            </button> </p>
            </div>
            )
            : (
              <>
                                  <button onClick={handleGoBack} className="button">
                  Go Back
                </button>
                {filteredJobs.map((job) => (
                  <div key={job.id} className="job-item">
                    <img src={job.image}/>
                    <p>{job.location}</p>
                    <h4>{job.position}</h4>
                    <div className='desc'>
                    <span>{job.desc}</span>
                    <div>
      <button className='btn-popup' onClick={togglePopup}>Apply</button>
      {showPopup && <Popup onClose={togglePopup} />}
    </div>
                    </div>
                    
                    

                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
